import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

//Images
import netCore from "../../src/assets/img/tech-icons/netCore.svg"
import htmlFive from "../../src/assets/img/tech-icons/htmlFive.svg"
import azure from "../../src/assets/img/tech-icons/azure.svg"
import angular from "../../src/assets/img/tech-icons/angular.svg"

import desktop from "../../src/assets/img/tech-icons/desktop.svg"
import mobile from "../../src/assets/img/tech-icons/mobile.svg"

import seventyFive from "../../src/assets/img/bars/75.svg"
import hundred from "../../src/assets/img/bars/100.svg"
import eightyTwo from "../../src/assets/img/bars/82.svg"
import eighty from "../../src/assets/img/bars/80.svg"
import seventyEight from "../../src/assets/img/bars/78.svg"
import movieFlow from "../../src/assets/img/movieFlow.svg" 
import movieFlowPhone from "../../src/assets/img/movieFlowPhone.svg" 


// Styles
// import "../sass/main.scss"
import styles from "../sass/pages/portfolio.module.scss" 

let lang = ""

const Movie = () => {

  lang = useIntl().locale;

  const data = useStaticQuery(graphql`
    query ImagesMovie {
      web: file( relativePath: { eq: "movieMockup.png"} ) {
          id
          childImageSharp { 
            fixed {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }
      picture: file( relativePath: { eq: "moviePicture.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
    }
    final: file( relativePath: { eq: "movieMockupFinal.png"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wireframeOne: file( relativePath: { eq: "movieWireframeOne.jpg"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wireframeTwo: file( relativePath: { eq: "movieWireframeTwo.jpg"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wireframeThree: file( relativePath: { eq: "movieWireframeThree.jpg"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wireframeFour: file( relativePath: { eq: "movieWireframeFour.jpg"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wireframeFive: file( relativePath: { eq: "movieWireframeFive.jpg"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wireframeSix: file( relativePath: { eq: "movieWireframeSix.jpg"} ) {
      id
      childImageSharp { 
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }


    }
  `);

  const title = useIntl().formatMessage({id: 'portfolio'});  

  const [load] = useState(0);

  // const verticalBand = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    // verticalBand.current.style.height = document.body.offsetHeight + "px";
  }, [load]);

  return (
    <Layout color="red">
    <SEO title={title} />
    {/* <div ref={ verticalBand } className={ styles.verticalBand }></div> */}
    
    <article> 
      <section>
        <div className={ "container-large " + styles.topCase + " " + styles.topCasemovie}>
          <div className={"grid-cards-2"}>
            <div>
              <h1 className="h3"><FormattedMessage id="case_movie_title"/></h1>
            </div> 
            <div>
              <p className="p-small"><FormattedMessage id="case_movie_paragraph_1" values={{breakLine:<br/>}}/></p>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article>
            
        <section className={"container-large " + styles.center}>
          <div className={styles.space}>
          <div className={"container-small " + styles.picture}>
              <figure>
                <Img fluid={ data.web.childImageSharp.fluid } alt="Movie Mockup Final" className={styles.movieMockup}/>
              </figure>
            </div>
          </div>
            
          <div className="container-small">
            <div className={"container"}>
              <h3><FormattedMessage id="case_movie_title_2"/></h3>
              <p><FormattedMessage id="case_movie_paragraph_2" values={{breakLine:<br/>}}/></p>
            </div>
            <div className={styles.spacemobile}>
            <h4><FormattedMessage id="case_movie_subtitle_1"/></h4>
            <div className={"grid-cards-3"}>
              <div className={ styles.picture}>
                <figure>
                  <Img fluid={ data.wireframeOne.childImageSharp.fluid } alt="Movie Picture" />
                </figure>
              </div>
              <div className={ styles.picture }>
                <figure>
                  <Img fluid={ data.wireframeTwo.childImageSharp.fluid } alt="Movie Picture" />
                </figure>
              </div>
              <div className={ styles.picture }>
                <figure>
                  <Img fluid={ data.wireframeThree.childImageSharp.fluid } alt="Movie Picture" />
                </figure>
              </div>
              </div>
              </div>
              <div className={styles.spacemobile}>
              <h4><FormattedMessage id="case_movie_subtitle_2"/></h4>
              <div className={"grid-cards-3"}>
              <div className={ styles.picture }>
                <figure>
                  <Img fluid={ data.wireframeFour.childImageSharp.fluid } alt="Movie Picture" />
                </figure>
              </div>
              <div className={ styles.picture }>
                <figure>
                  <Img fluid={ data.wireframeFive.childImageSharp.fluid } alt="Movie Picture" />
                </figure>
              </div>
              <div className={ styles.picture }>
                <figure>
                  <Img fluid={ data.wireframeSix.childImageSharp.fluid } alt="Movie Picture" />
                </figure>
              </div>
            </div>
            </div>
            <div className={styles.space}>
              <h3><FormattedMessage id="case_movie_title_3"/></h3>
            </div>
        </div>
      </section>
    </article>

    <article>
      <div className={ "container-large " }>
        <figure>
          <img src={movieFlow} alt="Movie Flow" className={styles.flow}/>
        </figure>
        <figure>
        <img src={movieFlowPhone} alt="Movie Flow" className={styles.flowMobile}/>
        </figure>
        </div>

      <section className={"container-small "}>
        <div className={styles.space}>

          <h3 className={styles.center}><FormattedMessage id="case_movie_title_4"/></h3>

        </div>
        <div className={"container-small" }>
        <div className={"grid-cards-2"}>
          <div>
            <div className={styles.userExperienceDataDesktop}>
              <img src={desktop} alt="Desktop"/><p><FormattedMessage id="desktop"/></p>
            </div>
            <div><img src={seventyFive} alt="75%" />
              <div className={styles.userExperienceData}>
                <p><FormattedMessage id="efficiency"/></p> <p>75%</p>
              </div>
            </div>
            <div><img src={hundred} alt="100%" />
              <div className={styles.userExperienceData}>
                <p><FormattedMessage id="effectiveness"/></p> <p>100%</p>
              </div>
            </div>
            <div><img src={eightyTwo} alt="82%" />
              <div className={styles.userExperienceData}>
                <p><FormattedMessage id="satisfaction"/></p> <p>82%</p>
              </div>
            </div>
          </div>

          <div>
            <div className={styles.userExperienceDataMobile}>
              <img src={mobile} alt="Mobile"/><p><FormattedMessage id="mobile"/></p>
            </div>
            <div><img src={eighty} alt="80%" />
              <div className={styles.userExperienceData}>
                <p><FormattedMessage id="efficiency"/></p> <p>80%</p>
              </div>
            </div>
            <div><img src={hundred} alt="100%" />
              <div className={styles.userExperienceData}>
                <p><FormattedMessage id="effectiveness"/></p> <p>100%</p>
              </div>
            </div>
            <div><img src={seventyEight} alt="78%" />
              <div className={styles.userExperienceData}>
                <p><FormattedMessage id="satisfaction"/></p> <p>78%</p>
              </div>
            </div>
          </div>

          


        </div>
        </div>
      </section>
      <div className={styles.space}>
        <div className={"container-large " + styles.movieMockupTwo}></div>
      </div>
    </article>


    <article>
      <section className={"container-small "+ styles.center}>
        <div className={"container-small "}>
         <div className={styles.space}>
          <h3><FormattedMessage id="case_movie_title_5"/></h3>
          <p><FormattedMessage id="case_movie_paragraph_3" values={{breakLine:<br/>}}/></p>
          </div>
        <div className={"grid-cards-4 " + styles.spaceBottom}>
          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={netCore} alt=".Net Core" />
            </div>
            <p><FormattedMessage id="net_core"/></p>
          </div>
        
          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={htmlFive} alt="HTML 5" />
            </div>
            <p><FormattedMessage id="html_5"/></p>
          </div>

          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={azure} alt="Microsoft Azure" />
            </div>
            <p><FormattedMessage id="microsoft_azure"/></p>
          </div>

          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={angular} alt="Angular" />
            </div>
            <p><FormattedMessage id="angular"/></p>
          </div>
        </div>

        </div>
      </section>
    </article>

       

    <article>
    <div className={styles.spaceSmall}></div>
      <div className={styles.topLineCaseMovie}></div>
        <div className={"container-large " + styles.result + " " + styles.movieResult}>
          <div className={"container-small " + styles.result}>
          <div className={"grid-cards-2-1 "}>
            <div><h3><FormattedMessage id="case_movie_title_6" values={{breakLine:<br/>}}/></h3><br/><p><FormattedMessage id="case_movie_paragraph_4" values={{breakLine:<br/>}}/></p>
              
              </div>
              <div className={ styles.picture }>
                  <figure className={styles.space + " " + styles.movieMockupFinal}>
                   <Img fixed={ data.final.childImageSharp.fixed } alt="Movie Mockup Final" />
                  </figure>
                </div>
            </div>
            </div>
            </div>
    </article>

    <article>  
      <div className={"bottomCards"}>
      <div className={"container-large container-wide-mobile " + styles.result}>
      <div className="container">
      <h3><FormattedMessage id="bottom_cards_title"/></h3>
            <div className={"grid-cards-2-1 "}>
            <Link to={`/${lang}` + "/voy-de-viaje"}>
            <div className={'portfolioCard bottomCard ' + styles.voyDeViaje}>
              <div className="cardContent ">
                  <p className="cardHashtags"></p>
                  <p className="cardTitle"><FormattedMessage id="first_project_title"/></p>
              </div>
            </div>
          </Link>
          <Link to={`/${lang}` + "/oca"}>
            <div className={'portfolioCard bottomCard ' + styles.oca}>
              <div className="cardContent ">
                  <p className="cardHashtags"></p>
                  <p className="cardTitle"><FormattedMessage id="third_project_title"/></p>
              </div>
            </div>
          </Link>
            </div>
        </div>
        </div>
        </div>  
    </article>        

    
        
 



    
    
  </Layout>
)}

export default Movie
